import { useCallback, useEffect } from 'react';

const usePageShowReload = () => {
  const handlePageShow = useCallback((e: PageTransitionEvent) => {
    if (!e.persisted) {
      return;
    }

    window.location.reload();
  }, []);

  useEffect(() => {
    window.addEventListener('pageshow', handlePageShow);

    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, [handlePageShow]);
};

export default usePageShowReload;
